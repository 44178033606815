var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.loading)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('CardPanel',{attrs:{"icon":"date_range","text":"Total de Eventos","endValue":_vm.eventos}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('CardPanel',{attrs:{"icon":"event_busy","text":"Eventos Pendentes","endValue":_vm.eventosPendentes}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('CardPanel',{attrs:{"icon":"event_available","text":"Eventos Agendados","endValue":_vm.eventosAgendados}})],1)],1),(_vm.custos.length && _vm.super_admin)?_c('v-row',_vm._l((_vm.custos),function(custo,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"4"}},[_c('CardPanel',{attrs:{"icon":"monetization_on","text":custo.descricao,"currency":custo.custo_medio}})],1)}),1):_vm._e(),_c('v-row',[(_vm.eventosEnvolvidos)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-toolbar',{staticClass:"justify-center d-flex",attrs:{"color":"toolbar","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1 white--text"},[_vm._v(" Eventos por Envolvidos ")])],1),_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.eventosEnvolvidos,"items-per-page":5,"footer-props":{
              itemsPerPageText: '',
            }}})],1)],1):_vm._e(),(_vm.eventosClassificacoes)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-toolbar',{staticClass:"justify-center d-flex",attrs:{"color":"toolbar","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1 white--text"},[_vm._v(" Eventos por Classificações ")])],1),_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.eventosClassificacoes,"items-per-page":5,"footer-props":{
              itemsPerPageText: '',
            }},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-regular text-capitalize",staticStyle:{"font-size":"0.68rem !important","letter-spacing":"0 !important"},attrs:{"text":"","small":"","to":{ path: 'eventos', query: { classificacao: item.id } }}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,false,785293395)})],1)],1):_vm._e(),(_vm.clienteTecnico)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-toolbar',{staticClass:"justify-center d-flex",attrs:{"color":"toolbar","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1 white--text"},[_vm._v(" Ranking de Clientes Tecnicos ")])],1),_c('v-data-table',{staticClass:"data-tables",attrs:{"headers":_vm.headers,"items":_vm.clienteTecnico,"items-per-page":5,"footer-props":{
              itemsPerPageText: '',
            }},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-regular text-capitalize",staticStyle:{"font-size":"0.68rem !important","letter-spacing":"0 !important"},attrs:{"text":"","small":"","to":{ path: 'eventos', query: { cliente: item.id } }}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}],null,false,3330324264)})],1)],1):_vm._e()],1)]:_c('Carregando')],2)}
var staticRenderFns = []

export { render, staticRenderFns }