<template>
  <v-container>
    <template v-if="!loading">
      <v-row>
        <v-col cols="12" md="4">
          <CardPanel
            icon="date_range"
            text="Total de Eventos"
            :endValue="eventos"
          />
        </v-col>
        <v-col cols="12" md="4">
          <CardPanel
            icon="event_busy"
            text="Eventos Pendentes"
            :endValue="eventosPendentes"
          />
        </v-col>
        <v-col cols="12" md="4">
          <CardPanel
            icon="event_available"
            text="Eventos Agendados"
            :endValue="eventosAgendados"
          />
        </v-col>
      </v-row>

      <v-row v-if="custos.length && super_admin">
        <v-col cols="12" md="4" v-for="(custo, index) in custos" :key="index">
          <CardPanel
            icon="monetization_on"
            :text="custo.descricao"
            :currency="custo.custo_medio"
          />
        </v-col>
      </v-row>

      <v-row>
        <!-- eventosEnvolvidos -->
        <v-col cols="12" md="4" v-if="eventosEnvolvidos">
          <v-card>
            <v-toolbar color="toolbar" dense flat class="justify-center d-flex">
              <v-toolbar-title class="subtitle-1 white--text">
                Eventos por Envolvidos
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="eventosEnvolvidos"
              :items-per-page="5"
              class="data-tables"
              :footer-props="{
                itemsPerPageText: '',
              }"
            ></v-data-table>
          </v-card>
        </v-col>

        <!-- eventosClassificacoes -->
        <v-col cols="12" md="4" v-if="eventosClassificacoes">
          <v-card>
            <v-toolbar color="toolbar" dense flat class="justify-center d-flex">
              <v-toolbar-title class="subtitle-1 white--text">
                Eventos por Classificações
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="eventosClassificacoes"
              :items-per-page="5"
              class="data-tables"
              :footer-props="{
                itemsPerPageText: '',
              }"
            >
              <template v-slot:item.text="{ item }">
                <v-btn
                  text
                  small
                  style="font-size: 0.68rem !important; letter-spacing: 0 !important;"
                  class="font-weight-regular text-capitalize"
                  :to="{ path: 'eventos', query: { classificacao: item.id } }"
                >
                  {{ item.text }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <!-- clienteTecnico -->
        <v-col cols="12" md="4" v-if="clienteTecnico">
          <v-card>
            <v-toolbar color="toolbar" dense flat class="justify-center d-flex">
              <v-toolbar-title class="subtitle-1 white--text">
                Ranking de Clientes Tecnicos
              </v-toolbar-title>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="clienteTecnico"
              :items-per-page="5"
              class="data-tables"
              :footer-props="{
                itemsPerPageText: '',
              }"
            >
              <template v-slot:item.text="{ item }">
                <v-btn
                  text
                  small
                  style="font-size: 0.68rem !important; letter-spacing: 0 !important;"
                  class="font-weight-regular text-capitalize"
                  :to="{ path: 'eventos', query: { cliente: item.id } }"
                >
                  {{ item.text }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <Carregando v-else />
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import {
  fetchEventosStats,
  fetchEventoCustos,
} from "@/services/api/eventos.api.js";

export default {
  name: "Home",
  components: {
    CardPanel: () => import("./components/CardPanel.vue"),
  },
  data() {
    return {
      eventos: null,
      eventosEnvolvidos: null,
      eventosClassificacoes: null,
      eventosPendentes: null,
      eventosAgendados: null,
      clienteTecnico: null,
      headers: [
        {
          text: "Nome",
          value: "text",
        },
        {
          text: "Total",
          value: "value",
        },
      ],
      loading: false,
      custos: [],
    };
  },
  computed: {
    ...mapState({
      light: (state) => state.template.light,
      dark: (state) => state.template.dark,
    }),
    ...mapState("Usuario", {
      avatar: (state) => state.usuario.avatar,
      super_admin: (state) => state.usuario.super_admin,
    }),
  },
  methods: {
    getStats() {
      this.loading = true;
      fetchEventosStats()
        .then((response) => {
          this.eventos = response.eventos;
          this.eventosEnvolvidos = response.eventos_por_envolvidos;
          this.eventosClassificacoes = response.eventos_por_classificacao;
          this.eventosPendentes = response.eventos_pendentes;
          this.eventosAgendados = response.eventos_agendados;
          this.clienteTecnico = response.cliente_ranking_tecnico;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getCustos() {
      this.loading = true;
      fetchEventoCustos()
        .then((response) => {
          this.custos = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    // this.$echo.channel("tocs").listen("NewMessage", (e) => {
    //   console.log(e);
    // });

    this.getStats();
    this.getCustos();
  },
  beforeDestroy() {
    // const channel = this.$echo.channel("tocs").listen("NewMessage");
    // channel.subscription.unbind(channel.eventFormatter.format("NewMessage"));
  },
};
</script>

<style></style>
